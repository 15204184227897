import type { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';
import * as React from 'react';
import type { ProductPagination } from '~source/core/models/components/molecules/product-teaser';
import type { ProductOverviewPage } from '~source/core/models/components/templates/page';
import getOverviewPage from '~source/core/services/api/get-product-page';
import getRedirect from '~source/core/services/api/get-redirect';
import addSizesToProducts from '~source/core/services/eva/api/product/add-sizes-to-products';
import {
  productPaginationEmpty,
  searchProductsByCollection,
} from '~source/core/services/eva/api/search-products';
import catchError from '~source/core/utils/catch-error';
import parseNumber from '~source/core/utils/parse-number';
import MetaTag from '~source/ui/components/atoms/meta-tag/meta-tag';
import StructuredProductCategoryHead from '~source/ui/components/atoms/structured-product-category-head/structured-product-category-head';
import type { ProductOptions } from '~source/ui/components/templates/module-handler/module-handler';
import ModuleHandler from '~source/ui/components/templates/module-handler/module-handler';
import useLocale from '~source/ui/hooks/helper/useLocale/useLocale';
import useFilteredProducts from '~source/ui/hooks/product/useFilteredProducts/useFilteredProducts';
import { useNavigation } from '~source/ui/hooks/ui/useNavigation';
import assertLocale from '~source/ui/utils/checks/assertLocale';
import setDYContext from '~source/ui/utils/dynamic-yield/set-dy-context';
import {
  addQueryString,
  getSingleValue,
} from '~source/ui/utils/urls/url-query';

type Props = {
  page: ProductOverviewPage;
  productPagination: ProductPagination;
};
type Params = {
  slugs: string[];
};

export default function ProductCollectionPage({
  page,
  productPagination,
}: Props) {
  const {
    modules,
    globalProductCollection,
    title,
    meta,
    categories,
    isTakeoverBanner,
    navigationStyles,
  } = page;
  const { products, totalProducts, totalPages } = productPagination;
  const locale = useLocale();
  const router = useRouter();
  const { setIsTakeoverBanner } = useNavigation();
  const currentPage = React.useMemo<number>(() => {
    const pageString = getSingleValue(router.query, 'page');
    return parseNumber(pageString) ?? 1;
  }, [router.query]);
  const {
    filteredProducts,
    filters,
    setFilter,
    sortDirection,
    setSortDirection,
    pageNumber,
  } = useFilteredProducts({
    productCollection: globalProductCollection,
    products,
    currentPage,
  });

  React.useEffect(() => {
    if (!title) return;
    // Title should be added to the categories array as a last item
    setDYContext('CATEGORY', locale, [...categories, title]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, title]);

  React.useEffect(() => {
    if (isTakeoverBanner) setIsTakeoverBanner(isTakeoverBanner);
  }, [isTakeoverBanner]);

  const productOptions: ProductOptions = {
    products: filteredProducts,
    selectedProductFilterOptions: filters,
    setProductFilterOption: setFilter,
    selectedSortDirection: sortDirection,
    setSortDirection,
    totalProducts,
    currentPage: pageNumber,
    totalPages,
    playerContext: page.context?.player,
  };

  return (
    <>
      <MetaTag {...meta} />
      <StructuredProductCategoryHead products={filteredProducts} />
      <ModuleHandler modules={modules} productOptions={productOptions} />
      <style dangerouslySetInnerHTML={{ __html: navigationStyles }} />
    </>
  );
}

ProductCollectionPage.localeRedirectToHome = true;

export const getServerSideProps: GetServerSideProps<Props, Params> = async ({
  params,
  query,
  locale,
}) => {
  assertLocale(locale);
  const { page: searchedPage } = query;
  const slug = params?.slugs.join('/');
  if (!slug) throw new Error('No slug found in params');
  const page = await getOverviewPage(slug, locale);
  const productCollection = page?.globalProductCollection ?? null;

  if (page && productCollection !== null) {
    const productPagination = await catchError(
      () =>
        searchProductsByCollection({
          collection: productCollection,
          locale,
        }).promise,
      productPaginationEmpty,
    );

    const extendedProducts = await catchError(() => {
      const { products } = productPagination;
      if (!products.length) return [];

      return addSizesToProducts(products);
    }, []);

    const { totalPages } = productPagination;

    if (searchedPage && Number(searchedPage) > totalPages) {
      const localeUrl =
        locale === 'en-US'
          ? process.env.NEXT_PUBLIC_HREF_EN
          : process.env.NEXT_PUBLIC_HREF;
      const url = `${localeUrl}/${slug}`;

      return {
        redirect: {
          destination: addQueryString(url, {
            page: totalPages,
          }),
          permanent: false,
        },
      };
    }

    return {
      props: {
        page,
        productPagination: {
          ...productPagination,
          products: extendedProducts,
        },
      },
    };
  }

  const redirect = await getRedirect(slug);
  if (redirect?.redirectTo) {
    return {
      redirect: {
        destination: redirect.redirectTo,
        permanent: false,
      },
    };
  }

  return {
    notFound: true,
  };
};
